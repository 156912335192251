.wrapper {
  & p {
    margin-top: 0;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  & > svg {
    margin-right: var(--spacing-3);
  }

  & h5 {
    font-size: 16px;
    margin: 0;
  }
}

.message {
  & button {
    display: inline;
    min-height: unset;
  }

  & button > span {
    color: var(--black);
    font-size: 14px;
  }
}
