.dividerSpacing {
  border-top: 1px solid var(--color-neutral-300);
  padding: var(--spacing-20) 0;
}

.modalContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    width: 100%;
  }
  @media (--tablet-l) {
    height: 90%;
    width: 90%;
  }
}

.modalBody {
  height: 100%;
  padding: 0;
}

.layout {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-6) var(--spacing-16);
  flex-wrap: wrap;
  height: 100%;

  @media (--desktop-l) {
    gap: var(--spacing-5) 80px;
  }

  :global .video {
    width: 100%;
    height: 100%;
  }
}
