.wrpr {
  display: none;

  @media (--tablet-l) {
    display: flex;
    gap: var(--spacing-8);
    height: 100%;
    flex-direction: column;
  }
}

.ad1,
.ad2 {
  flex: 0 0 50%;

  @media (--tablet-l) {
    & > div {
      position: sticky;
      top: var(--sticky-info-height, 112px);
    }
  }
}
