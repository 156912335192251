.caption {
  & a {
    color: inherit;
  }
}

.trustpilotWrapper {
  margin-top: var(--spacing-5);

  @media (--tablet-l) {
    display: none;
  }
}
