.mainWrapper {
  grid-area: 7 / 1;
  @media (--tablet-l) {
    grid-area: 2 / 1 / 3 / 3;
  }
}

.trustpilotWrapper {
  display: flex;
  flex-direction: row;
  color: inherit;
  text-decoration: none;
  gap: var(--spacing-3);
}

.ratingWrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
}

.trustpilotImage {
  height: 24px;
  margin-left: var(--spacing-2);
}

.littleLogo {
  color: var(--neutral-700-pressed-default-text, #303030);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}
